html,
body,
#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif !important;
}

@media (max-width: 600px) {
  html {
    font-size: 75%;
  }
}

#root {
  display: flex;
  justify-content: center;
  min-height: 80%;
  height: auto;
}
